import styled from "styled-components"

const IndividualContainer = styled.figure`
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-flex: 0;
    flex-shrink: 0;
    min-height: 250px;
`
export default IndividualContainer