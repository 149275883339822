// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-code-of-conduct-jsx": () => import("./../../../src/pages/code-of-conduct.jsx" /* webpackChunkName: "component---src-pages-code-of-conduct-jsx" */),
  "component---src-pages-discord-guide-jsx": () => import("./../../../src/pages/discord-guide.jsx" /* webpackChunkName: "component---src-pages-discord-guide-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-live-jsx": () => import("./../../../src/pages/live.jsx" /* webpackChunkName: "component---src-pages-live-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../../../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */),
  "component---src-pages-sponsor-info-jsx": () => import("./../../../src/pages/sponsor-info.jsx" /* webpackChunkName: "component---src-pages-sponsor-info-jsx" */),
  "component---src-pages-travel-policy-jsx": () => import("./../../../src/pages/travel-policy.jsx" /* webpackChunkName: "component---src-pages-travel-policy-jsx" */)
}

